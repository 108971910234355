import axios from 'axios';
import { toast } from 'react-toastify';
import Toast from '../components/Common/Tostify/Toastify';
import { getCookie } from '../utility/helper';

const baseURL = {
  prod: '',
  //dev: 'https://yz53iah1qg.execute-api.us-east-1.amazonaws.com/local',
  //dev: 'https://1e3lg11rlk.execute-api.us-east-1.amazonaws.com/prod',
  //dev: 'https://2r376j0hoj.execute-api.us-east-1.amazonaws.com/prod',
  //dev: 'https://z6810aseyb.execute-api.us-east-1.amazonaws.com/uat',
  //dev: 'https://fewkyj1uj4.execute-api.us-east-1.amazonaws.com/staging',
  dev: 'https://api.maxlearn.com',
};
let loginVal = true;
const helpDeskURL = {
  prod: '',
  //dev: 'https://notifications.codewave.com',
  // dev: 'https://maxlearnnotifications.codewave.com',
  //dev: 'https://notifications.uat.maxlearn.com',
  //dev: 'https://notifications.staging.maxlearn.com',
  dev: 'https://notifications.maxlearn.com',
};

//export const baseURL2 = 'https://notifications.codewave.com';
//export const baseURL2 = 'https://maxlearnnotifications.codewave.com';
//export const baseURL2 = 'https://notifications.uat.maxlearn.com';
//export const baseURL2 = 'https://notifications.staging.maxlearn.com';
export const baseURL2 = 'https://notifications.maxlearn.com';

export const learnerId = getCookie('userid');

const http = axios.create({ baseURL: baseURL?.dev });
let isToastShown = false;
http.interceptors.request.use(
  (request) => {
    const applicationId = getCookie('applicationId');
    const token = getCookie('TOKEN_NAME');
    request.headers['x-tenant-id'] = applicationId;
    request.headers['x-access-token'] = token;
    return request;
  },
  (err) => Promise.reject(err)
);
function clearAllCookies() {
  var cookies = document.cookie.split(';');

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf('=');
    var cookieName = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie =
      cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }
}
http.interceptors.response.use(
  (response) => {
    if (response?.data?.statusCode === 440 && loginVal === true) {
      Toast.error(response.data?.message);
      loginVal = false;
      setTimeout(() => {
        localStorage.clear();
        clearAllCookies();
        window.location.href = '/login';
      }, 2000);
    }
    return response;
  },
  async (error) => {
    if (error.response?.status === 440 && !isToastShown) {
      isToastShown = true;
      Toast.error(error.response?.data?.message);
      loginVal = false;
      setTimeout(() => {
        window.location.href = '/login';
        localStorage.clear();
        clearAllCookies();
      }, 2000);
    }
    return Promise.reject({ ...error });
  }
);

export default http;

export const httpNotification = axios.create({ baseURL: baseURL2 });

httpNotification.interceptors.request.use(
  (request) => {
    const applicationId = getCookie('applicationId');
    const token = getCookie('TOKEN_NAME');
    request.headers['x-tenant-id'] = applicationId;
    request.headers['x-access-token'] = token;
    return request;
  },
  (err) => Promise.reject(err)
);

export const https = axios.create({ baseURL: baseURL?.dev });

https.interceptors.request.use(
  (request) => {
    const applicationId = getCookie('applicationId');
    const token = getCookie('TOKEN_NAME');
    request.headers['x-tenant-id'] = applicationId;
    request.headers['x-access-token'] = token;
    return request;
  },
  (err) => Promise.reject(err)
);
